import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageWrapper } from './storage-wrapper';

@Injectable({ providedIn: 'root' })
export class LanguageSetup {

  public font_style: any = {};
  public lang: string = 'ja';

  private default_lang: string = 'ja';

  constructor(
    public translate: TranslateService,
    public storageWrapper: StorageWrapper) {

    translate.setDefaultLang(this.default_lang);
  }

  async update(new_lang) {
    console.log("update language: " + new_lang);

    await this.storageWrapper.setStorage("language", new_lang);

    this.lang = new_lang;
    this.translate.use(new_lang);

    if (new_lang == 'ja') {
      this.font_style = { 'font-family': 'NotoSansCJK' };
      return;
    }
    this.font_style = { 'font-family': 'Roboto' };
  }

  async load() {
    //let lang = await this.storageWrapper.getStorage("language");
    //ブラウザの利用言語情報から設定言語の変更(ja or en)(YM0追加)
    let lang = this.translate.getBrowserLang();
    if (lang == null) {
      await this.storageWrapper.setStorage("language", this.default_lang);
      lang = this.default_lang;
    }

    console.log("load language: " + lang);

    this.lang = lang;
    this.translate.use(lang);

    if (lang == 'ja') {
      this.font_style = { 'font-family': 'NotoSansCJK' };
      return;
    }
    this.font_style = { 'font-family': 'Roboto' };
  }

}
